/* ProjectCard.css */
.project-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
  }
  
  .project-card__image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-card__content {
    padding: 16px;
  }
  
  .project-card__title {
    font-size: 1.5rem;
    margin-bottom: 8px;
  }
  
  .project-card__description {
    font-size: 1rem;
    margin-bottom: 16px;
  }
  
  .project-card__link {
    color: #007bff;
    text-decoration: none;
  }
  
  .project-card__link:hover {
    text-decoration: underline;
  }
  